import i18n from 'i18next'
import LanguageDetector, { CustomDetector, DetectorOptions } from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

// if we want to always show one language as default, no matter what the browser says
// if you want to use the browser language, just remove the CustomDetector in the detection options
export const defaultLanguage = 'de'

const myDetector: CustomDetector = {
  name: 'myDetectorsName',

  lookup(options: DetectorOptions) {
    return (
      (options.lookupLocalStorage ? localStorage.getItem(options.lookupLocalStorage) : undefined) || defaultLanguage
    )
  },
}

const languageDetector = new LanguageDetector()
languageDetector.addDetector(myDetector)

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: { order: ['myDetectorsName'] },
    debug: false,
    fallbackLng: defaultLanguage,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          header: {
            title: `${import.meta.env.VITE_SITE_NAME}`,
            slogan: `I am ${import.meta.env.VITE_SITE_NAME} and I know (almost) everything published on <1>${
              import.meta.env.VITE_MAIN_SITE_SHORT
            }</1>.`,
          },
          from: 'from',

          results: {
            initial: `${
              import.meta.env.VITE_SITE_NAME
            } can now recognize your voice, even in Swiss German. Most of the time. [Click here for the button](/?speech=1).

We continue to work to improve, expand, and regularly update ${import.meta.env.VITE_SITE_NAME}. 
                      We therefore appreciate any feedback. Or even spreading the word if you are satisfied with ${
                        import.meta.env.VITE_SITE_NAME
                      }. `,
            initialEmpty: ``,
          },
          question: {
            label: 'Your Question',
            placeholder: 'Ask me anything about the administration of the City of Zurich.',
            buttonText: 'Ask Question',
            microphoneLabel: 'Ask Question (with Microphone)',
            microphoneListening: 'Microphone: Hold down the button and speak',
          },
          answers: {
            oneMoment: 'One moment please...',
            serverConnectionError: 'Connection to the server lost :( Please try again soon.',
            otherModelButton: 'Try with more context (experimental)',
            wasItUseful: 'Was the answer useful?',
            yes: 'Yes',
            no: 'No',
            giveFeedback: 'Give Feedback',
            readHints: 'Read Hints and Limitations',
            letsTryToImprove: "Let's try to improve the answer!",
            improvePrompt: 'Refine your question and try again',
            thanksForFeedback: 'Thanks for your feedback!',
            unhappy: 'Unhappy with the answer?',
          },
          hints: {
            title: 'Hints and Limitations',
            notesTitle: 'Hints',
            note1:
              'The bot does not remember previously asked questions. You need to enter the question again for refinements.',
            note2:
              'It is better to ask complete questions instead of just keywords. Sometimes, reformulating the question may result in a better answer.',
            note3:
              'Providing the current year (e.g., 2023) or asking more specific questions can yield more up-to-date answers.',
            note4: 'If no answer is received or the answer abruptly ends, it is best to try again.',
            note5:
              "We store your questions and answers with us so that we can improve the service over time. That's why we appreciate it if you give us feedback; it helps us tremendously. [Privacy Policy](/privacy)",
            note6:
              'All information is provided without warranty. Please verify the information on the official website of the City of Zurich.',
            limitationsTitle: 'Limitations',
            limitationsText: `${import.meta.env.VITE_SITE_NAME} knows (almost) everything that was published on <1>${
              import.meta.env.VITE_MAIN_SITE_SHORT
            }</1> on ${import.meta.env.VITE_DATE_EN}.`,
            disclaimerText: 'This app is not associated with the City of Zurich.',
          },
          contact: {
            title: 'Contact and Feedback',
            emailLabel: 'Your E-Mail (optional):',
            messageLabel: 'Your message:',
            submitButton: 'Submit',
            feedbackReceived: 'Feedback received, thank you!',
            feedbackReceivedError: 'Error while sending feedback, please try the other means to reach us.',
            emailText: 'Oder senden Sie einsend ann email at chregu@liip.ch',
            moreContact: 'More contact options at',
          },
          footer: {
            credits: 'Powered by <1>Liip</1> — <3>Blog Post about the project</3>',
            disclaimer: `${import.meta.env.VITE_SITE_NAME} is not affiliated with the City of Zurich.`,
            selectLanguage: 'Select Language',
          },
        },
      },
      de: {
        translation: {
          header: {
            title: `${import.meta.env.VITE_SITE_NAME}`,
            slogan: `Ich bin ${import.meta.env.VITE_SITE_NAME} und ich weiss (fast) alles,
             was auf <1>${import.meta.env.VITE_MAIN_SITE_SHORT}</1> publiziert ist.`,
          },
          from: 'vom',
          question: {
            label: 'Deine Frage',
            placeholder: 'Frag mich etwas über die Verwaltung der Stadt Zürich.',
            buttonText: 'Frage stellen',
            microphoneLabel: 'Frage stellen (mit Mikrofon)',
            microphoneListening:
              'Mikrofon: Halten Sie den Knopf gedrückt und sprechen Sie. Schweizerdeutsch geht auch. Meistens.',
          },
          results: {
            initial: `
            
Wichtig: Das ist ein sehr früher "Proof of Concept" und funktioniert vor allem bei sehr spezifischen Fragen, die sich auf die bestehenden Datensätze (siehe Liste unten) beziehen.

Die Beispiel Fragen geben einen guten Eindruck, was funktioniert. Je nach Datensatz hilt es, die Frage mit "gruppiere nach Zeit" zu ergänzen.

Für mehr generelle Fragen und nicht abgedeckte Daten, empfehlen wir auch [ZüriCityGPT](https://zuericitygpt.ch/) zu probieren.

***            
            
* Um die Suche auf einen Cube/Datensatz einzuschränken, gib einen der folgenden Cubes in der Frage als \`cubes:\` Parameter an.
           Z.B. \`cubes:https://ld.stadt-zuerich.ch/statistics/000279\` für den Altersdurchschnitt der ständigen Wohnbevölkerung. 

* Sollte die SPARQL Query einen Fehler werfen, kann man unter Umständen dem Generator etwas nachhelfen mit Tipps (siehe Beispiele)

* GPT-4.0 würde generell besser funktionieren, aus Kostengründen verzichten wir zur Zeit darauf in dieser Demo. Kontaktiere uns bei Interesse.

* Mehr Infos auch in unserem [Blog Post](https://www.liip.ch/en/blog/use-linkeddatagpt-to-query-open-linked-data-from-the-city-of-zurich). 

***
##### Beispiel Fragen:

* [Wie hat sich die ständige Wohnbevölkerung der Stadt Zürich entwickelt die letzten 100 Jahre gruppiere nach Zeit?](/?q=Wie+hat+sich+die+ständige+Wohnbevölkerung+der+Stadt+Zürich+entwickelt+die+letzten+100+Jahre+gruppiere+nach+Zeit%3F+)

* [Welches sind die 20 häufigsten Vornamen?](/?q=Welches%20sind%20die%2020%20h%C3%A4ufigsten%20Vornamen%3F)
* [Welche Sprache werden von der Wohnbevölkerung am meisten gesprochen?](/?q=Welche%20Sprache%20werden%20von%20der%20Wohnbev%C3%B6lkerung%20am%20meisten%20gesprochen%3F)
* [Was sind die häufigsten Sterbefallursachen?](/?q=Was+sind+die+häufigsten+Sterbefallursachen%3F)
* [Wieviele Personen mit Nationalität Deutschland lebten von 2010 und 2020 hier gruppiere nach Zeit? . cubes:https://ld.stadt-zuerich.ch/statistics/000288](/?q=Wieviele%20Personen%20mit%20Nationalit%C3%A4t%20Deutschland%20lebten%20von%202010%20und%202020%20hier%20gruppiere%20nach%20Zeit%3F%20.%20cubes%3Ahttps%3A//ld.stadt-zuerich.ch/statistics/000288)
* [Wie oft kommt der Vorname "Stefanie" vor in der Bevölkerung? Gruppiere und sortiere nach Geburtsjahr.](/?q=Wie+oft+kommt+der+Vorname+"Stefanie"+vor+in+der+Bevölkerung%3F+Gruppiere+und+sortiere+nach+Geburtsjahr.+)
* [Was sind die häufigsten Nachnamen?](/?q=Was%20sind%20die%20h%C3%A4ufigsten%20Nachnamen%3F)
* [Welche Religionen sind am beliebtesten?](/?q=Welche%20Religionen%20sind%20am%20beliebtesten%3F)
* [Was war das Durchschnittsalter nach Herkunft bei Sterbefällen?](/?q=Was%20war%20das%20Durchschnittsalter%20nach%20Herkunft%20bei%20Sterbef%C3%A4llen%3F)
* [Was war das Durchschnittsalter nach Herkunft und Geschlecht bei Sterbefällen?](/?q=Was%20war%20das%20Durchschnittsalter%20nach%20Herkunft%20und%20Geschlecht%20bei%20Sterbef%C3%A4llen%3F)
* [Wie hat sich das Durchschnittsalter bei Sterbefällen in den letzten 20 Jahren entwickelt?](/?q=Wie+hat+sich+das+Durchschnittsalter+bei+Sterbefällen+in+den+letzten+20+Jahren+entwickelt+gruppiere+nach+Zeit%3F)
* [Wie hat sich das Durchschnittsalter bei Sterbefällen in den letzten 20 Jahren bei Männern entwickelt?](/?q=Wie%20hat%20sich%20das%20Durchschnittsalter%20bei%20Sterbef%C3%A4llen%20in%20den%20letzten%2020%20Jahren%20bei%20M%C3%A4nnern%20entwickelt%3F)
* [Wie hat sich die Fertilitätsrate im Durchschnitt entwickelt in den letzten 50 Jahren?](/?q=Wie%20hat%20sich%20die%20Fertilit%C3%A4tsrate%20im%20Durchschnitt%20entwickelt%20in%20den%20letzten%2050%20Jahren%3F)
* [Wohin sind die Leute weggezogen die letzten 100 Jahre? Nimm Wegzugsland und WEZ for Count](/?q=Wohin%20sind%20die%20Leute%20weggezogen%20die%20letzten%20100%20Jahre%3F%20Nimm%20Wegzugsland%20und%20WEZ%20for%20Count)
* [Wohin sind die Leute weggezogen die letzten 100 Jahre? cubes:https://ld.stadt-zuerich.ch/statistics/000406](/?q=Wohin%20sind%20die%20Leute%20weggezogen%20die%20letzten%20100%20Jahre%3F%20cubes%3Ahttps%3A//ld.stadt-zuerich.ch/statistics/000406)
* [Wieviele Familien sind Weggezogen die letzten 10 Jahre? Gruppiere und sortiere bei Jahr](/?q=Wieviele+Familien+sind+Weggezogen+die+letzten+10+Jahre%3F+Gruppiere+und+sortiere+bei+Jahr+cubes%3Ahttps%3A%2F%2Fld.stadt-zuerich.ch%2Fstatistics%2F000401)
* [Durchschnittalter von Müttern bei der Geburt des ersten Kindes der letzten 100 Jahre nach Zeit gruppiert? cubes:https://ld.stadt-zuerich.ch/statistics/000349](/?q=Durchschnittalter%20von%20M%C3%BCttern%20bei%20der%20Geburt%20des%20ersten%20Kindes%20der%20letzten%20100%20Jahre%20nach%20Zeit%20gruppiert%3F%20cubes%3Ahttps%3A//ld.stadt-zuerich.ch/statistics/000349)
* [Wieviele Leute haben Luzern, Berne oder Appenzell zwischen 2015 - 2019 als Herkunftsort und Gruppiere nach Zeit und Herkunftsort? cubes:https://ld.stadt-zuerich.ch/statistics/000276](/?q=Wieviele%20Leute%20haben%20Luzern,%20Berne%20oder%20Appenzell%20zwischen%202015%20-%202019%20als%20Herkunftsort%20und%20Gruppiere%20nach%20Zeit%20und%20Herkunftsort%3F%20cubes%3Ahttps%3A//ld.stadt-zuerich.ch/statistics/000276)
* [Hat es mehr Frauen oder Männer in Zürich in 2022 ? cubes:https://ld.stadt-zuerich.ch/statistics/000277](/?q=Hat%20es%20mehr%20Frauen%20oder%20M%C3%A4nner%20in%20Z%C3%BCrich%20in%202022%20%3F%20cubes%3Ahttps%3A//ld.stadt-zuerich.ch/statistics/000277)
* [Welche Nationalität ist am meisten vertreten in Zürich in 2017? cubes:https://ld.stadt-zuerich.ch/statistics/000258?](/?q=Welche+Nationalität+ist+am+meisten+vertreten+in+Zürich+in+2017%3F+cubes%3Ahttps%3A%2F%2Fld.stadt-zuerich.ch%2Fstatistics%2F000258)
* [Welches Aufenthaltslevel ist am meisten vertreten in Zürich in 2016?](/?q=Welches%20Aufenthaltslevel%20ist%20am%20meisten%20vertreten%20in%20Z%C3%BCrich%20in%202016%3F)

##### Liste aller Cubes/Datensätze:

            
| cube | title |
| --- | --- |
| [https://ld.stadt-zuerich.ch/statistics/000279](https://ld.stadt-zuerich.ch/statistics/000279) | Altersdurchschnitt der ständigen Wohnbevölkerung |
| [https://ld.stadt-zuerich.ch/statistics/000281](https://ld.stadt-zuerich.ch/statistics/000281) | Altersdurchschnitt der ständigen Wohnbevölkerung nach Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000280](https://ld.stadt-zuerich.ch/statistics/000280) | Altersdurchschnitt der ständigen Wohnbevölkerung nach Herkunft |
| [https://ld.stadt-zuerich.ch/statistics/000282](https://ld.stadt-zuerich.ch/statistics/000282) | Altersdurchschnitt der ständigen Wohnbevölkerung nach Herkunft und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000325](https://ld.stadt-zuerich.ch/statistics/000325) | Anteil Ehelösungen durch Tod der Frau nach Ehedauer |
| [https://ld.stadt-zuerich.ch/statistics/000326](https://ld.stadt-zuerich.ch/statistics/000326) | Anteil Ehelösungen durch Tod des Mannes nach Ehedauer |
| [https://ld.stadt-zuerich.ch/statistics/000324](https://ld.stadt-zuerich.ch/statistics/000324) | Anteil Ehelösungen durch Tod nach Ehedauer |
| [https://ld.stadt-zuerich.ch/statistics/000320](https://ld.stadt-zuerich.ch/statistics/000320) | Anteil Ehelösungen nach Ehedauer |
| [https://ld.stadt-zuerich.ch/statistics/000331](https://ld.stadt-zuerich.ch/statistics/000331) | Anteil Ehescheidungen nach Altersklasse der Frau |
| [https://ld.stadt-zuerich.ch/statistics/000333](https://ld.stadt-zuerich.ch/statistics/000333) | Anteil Ehescheidungen nach Altersklasse der Frau und Altersklasse des Mannes |
| [https://ld.stadt-zuerich.ch/statistics/000332](https://ld.stadt-zuerich.ch/statistics/000332) | Anteil Ehescheidungen nach Altersklasse des Mannes |
| [https://ld.stadt-zuerich.ch/statistics/000321](https://ld.stadt-zuerich.ch/statistics/000321) | Anteil Ehescheidungen nach Ehedauer |
| [https://ld.stadt-zuerich.ch/statistics/000322](https://ld.stadt-zuerich.ch/statistics/000322) | Anteil Ehescheidungen von Frauen mit Wohnsitz in der Stadt Zürich nach Ehedauer |
| [https://ld.stadt-zuerich.ch/statistics/000323](https://ld.stadt-zuerich.ch/statistics/000323) | Anteil Ehescheidungen von Männern mit Wohnsitz in der Stadt Zürich nach Ehedauer |
| [https://ld.stadt-zuerich.ch/statistics/000314](https://ld.stadt-zuerich.ch/statistics/000314) | Anteil Eheschliessungen nach Altersklasse der Frau |
| [https://ld.stadt-zuerich.ch/statistics/000316](https://ld.stadt-zuerich.ch/statistics/000316) | Anteil Eheschliessungen nach Altersklasse der Frau und Altersklasse des Mannes |
| [https://ld.stadt-zuerich.ch/statistics/000315](https://ld.stadt-zuerich.ch/statistics/000315) | Anteil Eheschliessungen nach Altersklasse des Mannes |
| [https://ld.stadt-zuerich.ch/statistics/000258](https://ld.stadt-zuerich.ch/statistics/000258) | Aufenthaltsart der ausländischen Bevölkerung nach Nationalität und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000247](https://ld.stadt-zuerich.ch/statistics/000247) | Aufenthaltsdauer in Jahren (Perzentile) bei wirtschaftlichen Wegzügen nach 20-Jahres-Altersklassen; Herkunft und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000440](https://ld.stadt-zuerich.ch/statistics/000440) | Aufenthaltsdauer in Jahren (Perzentile) der wirtschaftlichen Bevölkerung |
| [https://ld.stadt-zuerich.ch/statistics/000245](https://ld.stadt-zuerich.ch/statistics/000245) | Aufenthaltsdauer in Jahren (Perzentile) der wirtschaftlichen Bevölkerung nach 20-Jahres-Altersklassen; Herkunft und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000253](https://ld.stadt-zuerich.ch/statistics/000253) | Ausländer*innen-Anteil der wirtschaftlichen Wohnbevölkerung |
| [https://ld.stadt-zuerich.ch/statistics/000287](https://ld.stadt-zuerich.ch/statistics/000287) | Ausländische Wohnbevölkerung nach Altersklasse (Lebensabschnitte) und Nationalität |
| [https://ld.stadt-zuerich.ch/statistics/000288](https://ld.stadt-zuerich.ch/statistics/000288) | Ausländische Wohnbevölkerung nach Aufenthaltsart (Level2) und Nationalität |
| [https://ld.stadt-zuerich.ch/statistics/000289](https://ld.stadt-zuerich.ch/statistics/000289) | Ausländische Wohnbevölkerung nach Aufenthaltsart (Level2); Nationalität und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000327](https://ld.stadt-zuerich.ch/statistics/000327) | Beendete Ehen |
| [https://ld.stadt-zuerich.ch/statistics/000349](https://ld.stadt-zuerich.ch/statistics/000349) | Durchschnittalter von Müttern bei der Geburt des ersten Kindes in der Ehe |
| [https://ld.stadt-zuerich.ch/statistics/000351](https://ld.stadt-zuerich.ch/statistics/000351) | Durchschnittalter von Müttern bei der Geburt des ersten Kindes in der Ehe nach Herkunft |
| [https://ld.stadt-zuerich.ch/statistics/000350](https://ld.stadt-zuerich.ch/statistics/000350) | Durchschnittalter von Müttern bei der Geburt nach Ehelichkeit |
| [https://ld.stadt-zuerich.ch/statistics/000352](https://ld.stadt-zuerich.ch/statistics/000352) | Durchschnittalter von Müttern bei der Geburt nach Ehelichkeit und Herkunft |
| [https://ld.stadt-zuerich.ch/statistics/000248](https://ld.stadt-zuerich.ch/statistics/000248) | Durchschnittliche Aufenthaltsdauer in Jahren bei wirtschaftlichen Wegzügen nach 20-Jahres-Altersklassen; Herkunft und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000246](https://ld.stadt-zuerich.ch/statistics/000246) | Durchschnittliche Aufenthaltsdauer in Jahren der wirtschaftlichen Bevölkerung nach 20-Jahres-Altersklassen; Herkunft und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000240](https://ld.stadt-zuerich.ch/statistics/000240) | Durchschnittsalter bei Sterbefällen |
| [https://ld.stadt-zuerich.ch/statistics/000241](https://ld.stadt-zuerich.ch/statistics/000241) | Durchschnittsalter bei Sterbefällen nach Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000242](https://ld.stadt-zuerich.ch/statistics/000242) | Durchschnittsalter bei Sterbefällen nach Herkunft |
| [https://ld.stadt-zuerich.ch/statistics/000243](https://ld.stadt-zuerich.ch/statistics/000243) | Durchschnittsalter bei Sterbefällen nach Herkunft und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000221](https://ld.stadt-zuerich.ch/statistics/000221) | Durchschnittsalter nach Geschlecht bei Eheschliessungen (Erstverheiratung) |
| [https://ld.stadt-zuerich.ch/statistics/000225](https://ld.stadt-zuerich.ch/statistics/000225) | Durchschnittsalter nach Geschlecht bei eingetragenen Partnerschaften (Erste Eintragung) |
| [https://ld.stadt-zuerich.ch/statistics/000365](https://ld.stadt-zuerich.ch/statistics/000365) | Eheliche Geburten (Vorgang) nach Altersklasse der Mutter |
| [https://ld.stadt-zuerich.ch/statistics/000367](https://ld.stadt-zuerich.ch/statistics/000367) | Eheliche Geburten (Vorgang) nach Altersklasse der Mutter und Altersklasse des Vaters |
| [https://ld.stadt-zuerich.ch/statistics/000366](https://ld.stadt-zuerich.ch/statistics/000366) | Eheliche Geburten (Vorgang) nach Altersklasse des Vaters |
| [https://ld.stadt-zuerich.ch/statistics/000358](https://ld.stadt-zuerich.ch/statistics/000358) | Eheliche Lebendgeborene mach Geburtenreihenfolge |
| [https://ld.stadt-zuerich.ch/statistics/000359](https://ld.stadt-zuerich.ch/statistics/000359) | Eheliche Lebendgeborene mach Geburtenreihenfolge und Herkunft |
| [https://ld.stadt-zuerich.ch/statistics/000329](https://ld.stadt-zuerich.ch/statistics/000329) | Ehelösungen durch Tod eines Partners |
| [https://ld.stadt-zuerich.ch/statistics/000330](https://ld.stadt-zuerich.ch/statistics/000330) | Ehelösungen durch Tod eines Partners nach Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000328](https://ld.stadt-zuerich.ch/statistics/000328) | Ehescheidende mit Wohnsitz in der Stadt Zürich nach Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000219](https://ld.stadt-zuerich.ch/statistics/000219) | Ehescheidungen |
| [https://ld.stadt-zuerich.ch/statistics/000334](https://ld.stadt-zuerich.ch/statistics/000334) | Ehescheidungen nach Altersklasse der Frau |
| [https://ld.stadt-zuerich.ch/statistics/000336](https://ld.stadt-zuerich.ch/statistics/000336) | Ehescheidungen nach Altersklasse der Frau und Altersklasse des Mannes |
| [https://ld.stadt-zuerich.ch/statistics/000335](https://ld.stadt-zuerich.ch/statistics/000335) | Ehescheidungen nach Altersklasse des Mannes |
| [https://ld.stadt-zuerich.ch/statistics/000220](https://ld.stadt-zuerich.ch/statistics/000220) | Ehescheidungen nach Anzahl unmündiger Kinder |
| [https://ld.stadt-zuerich.ch/statistics/000218](https://ld.stadt-zuerich.ch/statistics/000218) | Eheschliessungen |
| [https://ld.stadt-zuerich.ch/statistics/000317](https://ld.stadt-zuerich.ch/statistics/000317) | Eheschliessungen nach Altersklasse der Frau |
| [https://ld.stadt-zuerich.ch/statistics/000319](https://ld.stadt-zuerich.ch/statistics/000319) | Eheschliessungen nach Altersklasse der Frau und Altersklasse des Mannes |
| [https://ld.stadt-zuerich.ch/statistics/000318](https://ld.stadt-zuerich.ch/statistics/000318) | Eheschliessungen nach Altersklasse des Mannes |
| [https://ld.stadt-zuerich.ch/statistics/000304](https://ld.stadt-zuerich.ch/statistics/000304) | Eheschliessungen nach Nationalität der Frau |
| [https://ld.stadt-zuerich.ch/statistics/000306](https://ld.stadt-zuerich.ch/statistics/000306) | Eheschliessungen nach Nationalität der Frau und Nationalität des Mannes |
| [https://ld.stadt-zuerich.ch/statistics/000305](https://ld.stadt-zuerich.ch/statistics/000305) | Eheschliessungen nach Nationalität des Mannes |
| [https://ld.stadt-zuerich.ch/statistics/000217](https://ld.stadt-zuerich.ch/statistics/000217) | Eheschliessungen nach Nationalität und Geschlecht der Eheschliessenden |
| [https://ld.stadt-zuerich.ch/statistics/000303](https://ld.stadt-zuerich.ch/statistics/000303) | Eheschliessungen nach Wohnsitz der Eheschliessenden |
| [https://ld.stadt-zuerich.ch/statistics/000307](https://ld.stadt-zuerich.ch/statistics/000307) | Eheschliessungen nach Zivilstand vor Ehe der Frau |
| [https://ld.stadt-zuerich.ch/statistics/000309](https://ld.stadt-zuerich.ch/statistics/000309) | Eheschliessungen nach Zivilstand vor Ehe der Frau und Zivilstand vor Ehe des Mannes |
| [https://ld.stadt-zuerich.ch/statistics/000308](https://ld.stadt-zuerich.ch/statistics/000308) | Eheschliessungen nach Zivilstand vor Ehe des Mannes |
| [https://ld.stadt-zuerich.ch/statistics/000224](https://ld.stadt-zuerich.ch/statistics/000224) | Eingetragene Partnerschaften nach Herkunft und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000211](https://ld.stadt-zuerich.ch/statistics/000211) | Evangelisch-reformierte wirtschaftliche Bevölkerung nach Herkunft |
| [https://ld.stadt-zuerich.ch/statistics/000212](https://ld.stadt-zuerich.ch/statistics/000212) | Evangelisch-reformierte wirtschaftliche Bevölkerung nach Herkunft und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000210](https://ld.stadt-zuerich.ch/statistics/000210) | Evangelisch-reformierte wirtschaftliche Bevölkerung nach evangelisch-reformierter Kirchgemeinde |
| [https://ld.stadt-zuerich.ch/statistics/000363](https://ld.stadt-zuerich.ch/statistics/000363) | Fertilitätsrate |
| [https://ld.stadt-zuerich.ch/statistics/000364](https://ld.stadt-zuerich.ch/statistics/000364) | Fertilitätsrate nach Herkunft |
| [https://ld.stadt-zuerich.ch/statistics/000452](https://ld.stadt-zuerich.ch/statistics/000452) | Geburtenrate (wirtschaftlich) |
| [https://ld.stadt-zuerich.ch/statistics/000237](https://ld.stadt-zuerich.ch/statistics/000237) | Haushalte nach Anzahl Kindern und Haushaltstyp |
| [https://ld.stadt-zuerich.ch/statistics/000236](https://ld.stadt-zuerich.ch/statistics/000236) | Haushalte nach Haushaltsgrösse |
| [https://ld.stadt-zuerich.ch/statistics/000235](https://ld.stadt-zuerich.ch/statistics/000235) | Haushalte nach Haushaltstyp |
| [https://ld.stadt-zuerich.ch/statistics/000297](https://ld.stadt-zuerich.ch/statistics/000297) | Haushalte nach Zuordenbarkeit in Haushalte |
| [https://ld.stadt-zuerich.ch/statistics/000298](https://ld.stadt-zuerich.ch/statistics/000298) | Haushalte nach Zuordenbarkeit in Haushalte und Zimmerzahl der Wohnungen |
| [https://ld.stadt-zuerich.ch/statistics/000337](https://ld.stadt-zuerich.ch/statistics/000337) | Lebendgeborene |
| [https://ld.stadt-zuerich.ch/statistics/000340](https://ld.stadt-zuerich.ch/statistics/000340) | Lebendgeborene nach Ehelichkeit |
| [https://ld.stadt-zuerich.ch/statistics/000339](https://ld.stadt-zuerich.ch/statistics/000339) | Lebendgeborene nach Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000343](https://ld.stadt-zuerich.ch/statistics/000343) | Lebendgeborene nach Geschlecht und Ehelichkeit |
| [https://ld.stadt-zuerich.ch/statistics/000345](https://ld.stadt-zuerich.ch/statistics/000345) | Lebendgeborene nach HeimNationalitätatland der Mutter |
| [https://ld.stadt-zuerich.ch/statistics/000338](https://ld.stadt-zuerich.ch/statistics/000338) | Lebendgeborene nach Herkunft |
| [https://ld.stadt-zuerich.ch/statistics/000344](https://ld.stadt-zuerich.ch/statistics/000344) | Lebendgeborene nach Herkunft Geschlecht und Ehelichkeit |
| [https://ld.stadt-zuerich.ch/statistics/000342](https://ld.stadt-zuerich.ch/statistics/000342) | Lebendgeborene nach Herkunft und Ehelichkeit |
| [https://ld.stadt-zuerich.ch/statistics/000341](https://ld.stadt-zuerich.ch/statistics/000341) | Lebendgeborene nach Herkunft und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000346](https://ld.stadt-zuerich.ch/statistics/000346) | Lebendgeborene nach Nationalität der Mutter und Ehelichkeit |
| [https://ld.stadt-zuerich.ch/statistics/000347](https://ld.stadt-zuerich.ch/statistics/000347) | Lebendgeborene nach Zivilstand der Mutter |
| [https://ld.stadt-zuerich.ch/statistics/000348](https://ld.stadt-zuerich.ch/statistics/000348) | Lebendgeborene nach Zivilstand der Mutter und Ehelichkeit |
| [https://ld.stadt-zuerich.ch/statistics/000361](https://ld.stadt-zuerich.ch/statistics/000361) | Lebendgeborene nach erstem Vornamen und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000227](https://ld.stadt-zuerich.ch/statistics/000227) | Nationalitäten |
| [https://ld.stadt-zuerich.ch/statistics/000299](https://ld.stadt-zuerich.ch/statistics/000299) | Personen pro Haushalt |
| [https://ld.stadt-zuerich.ch/statistics/000300](https://ld.stadt-zuerich.ch/statistics/000300) | Personen pro Haushalt nach Zimmerzahl der Wohnungen |
| [https://ld.stadt-zuerich.ch/statistics/000214](https://ld.stadt-zuerich.ch/statistics/000214) | Römisch-katholische wirtschaftliche Bevölkerung nach Herkunft |
| [https://ld.stadt-zuerich.ch/statistics/000215](https://ld.stadt-zuerich.ch/statistics/000215) | Römisch-katholische wirtschaftliche Bevölkerung nach Herkunft und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000213](https://ld.stadt-zuerich.ch/statistics/000213) | Römisch-katholische wirtschaftliche Bevölkerung nach römisch-katholischer Kirchgemeinde |
| [https://ld.stadt-zuerich.ch/statistics/000427](https://ld.stadt-zuerich.ch/statistics/000427) | Saldo aller wirtschaftlichen Bevölkerungsbewegungen |
| [https://ld.stadt-zuerich.ch/statistics/000426](https://ld.stadt-zuerich.ch/statistics/000426) | Saldo aller wirtschaftlichen Umzugsbewegungen |
| [https://ld.stadt-zuerich.ch/statistics/000378](https://ld.stadt-zuerich.ch/statistics/000378) | Saldo der natürlichen Bevölkerungsbewegungen (wirtschaftlich) |
| [https://ld.stadt-zuerich.ch/statistics/000379](https://ld.stadt-zuerich.ch/statistics/000379) | Saldo der natürlichen Bevölkerungsbewegungen (wirtschaftlich) nach Herkunft |
| [https://ld.stadt-zuerich.ch/statistics/000384](https://ld.stadt-zuerich.ch/statistics/000384) | Saldo der wirtschaftlichen Wanderungsbewegungen |
| [https://ld.stadt-zuerich.ch/statistics/000388](https://ld.stadt-zuerich.ch/statistics/000388) | Saldo der wirtschaftlichen Wanderungsbewegungen nach Altersklasse |
| [https://ld.stadt-zuerich.ch/statistics/000393](https://ld.stadt-zuerich.ch/statistics/000393) | Saldo der wirtschaftlichen Wanderungsbewegungen nach Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000394](https://ld.stadt-zuerich.ch/statistics/000394) | Saldo der wirtschaftlichen Wanderungsbewegungen nach Geschlecht und Zivilstand |
| [https://ld.stadt-zuerich.ch/statistics/000409](https://ld.stadt-zuerich.ch/statistics/000409) | Saldo der wirtschaftlichen Wanderungsbewegungen nach Kanton |
| [https://ld.stadt-zuerich.ch/statistics/000397](https://ld.stadt-zuerich.ch/statistics/000397) | Saldo der wirtschaftlichen Wanderungsbewegungen nach Konfession |
| [https://ld.stadt-zuerich.ch/statistics/000408](https://ld.stadt-zuerich.ch/statistics/000408) | Saldo der wirtschaftlichen Wanderungsbewegungen nach Land |
| [https://ld.stadt-zuerich.ch/statistics/000385](https://ld.stadt-zuerich.ch/statistics/000385) | Saldo der wirtschaftlichen Wanderungsbewegungen nach Nationalität |
| [https://ld.stadt-zuerich.ch/statistics/000402](https://ld.stadt-zuerich.ch/statistics/000402) | Saldo der wirtschaftlichen Wanderungsbewegungen von Familien |
| [https://ld.stadt-zuerich.ch/statistics/000403](https://ld.stadt-zuerich.ch/statistics/000403) | Saldo der wirtschaftlichen Wanderungsbewegungen von Familien nach Herkunft |
| [https://ld.stadt-zuerich.ch/statistics/000277](https://ld.stadt-zuerich.ch/statistics/000277) | Schweizer Bevölkerung nach Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000278](https://ld.stadt-zuerich.ch/statistics/000278) | Schweizer Bevölkerung nach Geschlecht und Heimatkanton |
| [https://ld.stadt-zuerich.ch/statistics/000276](https://ld.stadt-zuerich.ch/statistics/000276) | Schweizer Bevölkerung nach Heimatkanton |
| [https://ld.stadt-zuerich.ch/statistics/000283](https://ld.stadt-zuerich.ch/statistics/000283) | Ständige Wohnevölkerung |
| [https://ld.stadt-zuerich.ch/statistics/000272](https://ld.stadt-zuerich.ch/statistics/000272) | Ständige Wohnevölkerung ab 15 Jahren |
| [https://ld.stadt-zuerich.ch/statistics/000273](https://ld.stadt-zuerich.ch/statistics/000273) | Ständige Wohnevölkerung ab 15 Jahren nach Herkunft |
| [https://ld.stadt-zuerich.ch/statistics/000292](https://ld.stadt-zuerich.ch/statistics/000292) | Ständige Wohnevölkerung ab 15 Jahren nach Herkunft und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000275](https://ld.stadt-zuerich.ch/statistics/000275) | Ständige Wohnevölkerung ab 15 Jahren nach Herkunft und Religion |
| [https://ld.stadt-zuerich.ch/statistics/000294](https://ld.stadt-zuerich.ch/statistics/000294) | Ständige Wohnevölkerung ab 15 Jahren nach Herkunft; Geschlecht und sozioprofessioneller Kategorie |
| [https://ld.stadt-zuerich.ch/statistics/000274](https://ld.stadt-zuerich.ch/statistics/000274) | Ständige Wohnevölkerung ab 15 Jahren nach Religion |
| [https://ld.stadt-zuerich.ch/statistics/000291](https://ld.stadt-zuerich.ch/statistics/000291) | Ständige Wohnevölkerung ab 15 Jahren nach Sprache |
| [https://ld.stadt-zuerich.ch/statistics/000293](https://ld.stadt-zuerich.ch/statistics/000293) | Ständige Wohnevölkerung ab 15 Jahren nach sozioprofessioneller Kategorie |
| [https://ld.stadt-zuerich.ch/statistics/000285](https://ld.stadt-zuerich.ch/statistics/000285) | Ständige Wohnevölkerung nach Herkunft |
| [https://ld.stadt-zuerich.ch/statistics/000286](https://ld.stadt-zuerich.ch/statistics/000286) | Ständige Wohnevölkerung nach Herkunft und Zivilstand |
| [https://ld.stadt-zuerich.ch/statistics/000290](https://ld.stadt-zuerich.ch/statistics/000290) | Ständige Wohnevölkerung nach Sprache |
| [https://ld.stadt-zuerich.ch/statistics/000284](https://ld.stadt-zuerich.ch/statistics/000284) | Ständige Wohnevölkerung nach Zivilstand |
| [https://ld.stadt-zuerich.ch/statistics/000373](https://ld.stadt-zuerich.ch/statistics/000373) | Todesfälle nach Altersklasse |
| [https://ld.stadt-zuerich.ch/statistics/000369](https://ld.stadt-zuerich.ch/statistics/000369) | Todesfälle nach Altersklasse |
| [https://ld.stadt-zuerich.ch/statistics/000370](https://ld.stadt-zuerich.ch/statistics/000370) | Todesfälle nach Altersklasse und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000372](https://ld.stadt-zuerich.ch/statistics/000372) | Todesfälle nach Altersklasse und Todesursache |
| [https://ld.stadt-zuerich.ch/statistics/000374](https://ld.stadt-zuerich.ch/statistics/000374) | Todesfälle nach Altersklasse;Todesursache und Geschlecht (REDUNDANZ mit CID000250 und CID000251) |
| [https://ld.stadt-zuerich.ch/statistics/000368](https://ld.stadt-zuerich.ch/statistics/000368) | Todesfälle nach Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000377](https://ld.stadt-zuerich.ch/statistics/000377) | Todesfälle nach Herkunft |
| [https://ld.stadt-zuerich.ch/statistics/000250](https://ld.stadt-zuerich.ch/statistics/000250) | Todesfälle nach Todesursache; Altersklasse und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000376](https://ld.stadt-zuerich.ch/statistics/000376) | Todesfälle nach Todesursachenklassen |
| [https://ld.stadt-zuerich.ch/statistics/000251](https://ld.stadt-zuerich.ch/statistics/000251) | Todesfälle nach aggregierter Todesursache; Altersklasse und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000201](https://ld.stadt-zuerich.ch/statistics/000201) | Wirtschaftliche Bevölkerung |
| [https://ld.stadt-zuerich.ch/statistics/000434](https://ld.stadt-zuerich.ch/statistics/000434) | Wirtschaftliche Bevölkerung (nur Bevölkerung berücksichtigt; welche eindeutig einem Haushalt zugeordnet werden kann) |
| [https://ld.stadt-zuerich.ch/statistics/000238](https://ld.stadt-zuerich.ch/statistics/000238) | Wirtschaftliche Bevölkerung nach 1-Jahres-Altersklasse; Herkunft und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000206](https://ld.stadt-zuerich.ch/statistics/000206) | Wirtschaftliche Bevölkerung nach 1-Jahresaltersklasse ab 1993 |
| [https://ld.stadt-zuerich.ch/statistics/000208](https://ld.stadt-zuerich.ch/statistics/000208) | Wirtschaftliche Bevölkerung nach 1-Jahresaltersklasse und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000209](https://ld.stadt-zuerich.ch/statistics/000209) | Wirtschaftliche Bevölkerung nach 1-Jahresaltersklasse und Herkunft |
| [https://ld.stadt-zuerich.ch/statistics/000233](https://ld.stadt-zuerich.ch/statistics/000233) | Wirtschaftliche Bevölkerung nach 10-Jahres-Altersklasse und Haushaltstyp |
| [https://ld.stadt-zuerich.ch/statistics/000249](https://ld.stadt-zuerich.ch/statistics/000249) | Wirtschaftliche Bevölkerung nach 5-Jahresaltersklasse |
| [https://ld.stadt-zuerich.ch/statistics/000207](https://ld.stadt-zuerich.ch/statistics/000207) | Wirtschaftliche Bevölkerung nach 5-Jahresaltersklasse ab 1993 |
| [https://ld.stadt-zuerich.ch/statistics/000216](https://ld.stadt-zuerich.ch/statistics/000216) | Wirtschaftliche Bevölkerung nach Aufenthaltsart (Level 2) und Herkunft von 2015 bis 2016 ganze Stadt |
| [https://ld.stadt-zuerich.ch/statistics/000354](https://ld.stadt-zuerich.ch/statistics/000354) | Wirtschaftliche Bevölkerung nach Aufenthaltsart; Nationalität und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000203](https://ld.stadt-zuerich.ch/statistics/000203) | Wirtschaftliche Bevölkerung nach Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000234](https://ld.stadt-zuerich.ch/statistics/000234) | Wirtschaftliche Bevölkerung nach Haushaltsgrösse |
| [https://ld.stadt-zuerich.ch/statistics/000433](https://ld.stadt-zuerich.ch/statistics/000433) | Wirtschaftliche Bevölkerung nach Haushaltstyp (nur Bevölkerung berücksichtigt; welche eindeutig einem Haushalt zugeordnet werden kann) |
| [https://ld.stadt-zuerich.ch/statistics/000202](https://ld.stadt-zuerich.ch/statistics/000202) | Wirtschaftliche Bevölkerung nach Herkunft |
| [https://ld.stadt-zuerich.ch/statistics/000205](https://ld.stadt-zuerich.ch/statistics/000205) | Wirtschaftliche Bevölkerung nach Herkunft 2010 bis 2015 nach Stadtkreisen |
| [https://ld.stadt-zuerich.ch/statistics/000204](https://ld.stadt-zuerich.ch/statistics/000204) | Wirtschaftliche Bevölkerung nach Herkunft und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000451](https://ld.stadt-zuerich.ch/statistics/000451) | Wirtschaftliche Bevölkerung nach Herkunft;5-Jahres-Altersklassen und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000270](https://ld.stadt-zuerich.ch/statistics/000270) | Wirtschaftliche Bevölkerung nach Nachnamen |
| [https://ld.stadt-zuerich.ch/statistics/000228](https://ld.stadt-zuerich.ch/statistics/000228) | Wirtschaftliche Bevölkerung nach Region |
| [https://ld.stadt-zuerich.ch/statistics/000256](https://ld.stadt-zuerich.ch/statistics/000256) | Wirtschaftliche Bevölkerung nach Stadtquartier; Stadtkreis; Stadt; Zivistand;10-Jahres-Altersklassen und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000257](https://ld.stadt-zuerich.ch/statistics/000257) | Wirtschaftliche Bevölkerung nach Stadtquartier; Stadtkreis; Stadt; Zivistand;20-Jahres-Altersklassen und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000255](https://ld.stadt-zuerich.ch/statistics/000255) | Wirtschaftliche Bevölkerung nach Stadtquartier; Stadtkreis; Stadt; Zivistand;5-Jahres-Altersklassen und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000254](https://ld.stadt-zuerich.ch/statistics/000254) | Wirtschaftliche Bevölkerung nach Stadtquartier; Stadtkreis; Stadt; Zivistand; Alter und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000295](https://ld.stadt-zuerich.ch/statistics/000295) | Wirtschaftliche Bevölkerung nach Zuordenbarkeit in Haushalte |
| [https://ld.stadt-zuerich.ch/statistics/000296](https://ld.stadt-zuerich.ch/statistics/000296) | Wirtschaftliche Bevölkerung nach Zuordenbarkeit in Haushalte und Haushaltsgrösse |
| [https://ld.stadt-zuerich.ch/statistics/000437](https://ld.stadt-zuerich.ch/statistics/000437) | Wirtschaftliche Bevölkerung nach erstem Vornamen; Geburtsjahr und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000419](https://ld.stadt-zuerich.ch/statistics/000419) | Wirtschaftliche Bürgerrechtswechsel ins Bürgerrecht der Stadt Zürich nach Einbürgerungsart von Ausländerinnen und Ausländern |
| [https://ld.stadt-zuerich.ch/statistics/000424](https://ld.stadt-zuerich.ch/statistics/000424) | Wirtschaftliche Bürgerrechtswechsel ins Bürgerrecht der Stadt Zürich nach Herkunft |
| [https://ld.stadt-zuerich.ch/statistics/000416](https://ld.stadt-zuerich.ch/statistics/000416) | Wirtschaftliche Bürgerrechtswechsel ins Bürgerrecht der Stadt Zürich von Ausländerinnen und Ausländern nach Einbürgerungsart und bisheriger Nationalität |
| [https://ld.stadt-zuerich.ch/statistics/000425](https://ld.stadt-zuerich.ch/statistics/000425) | Wirtschaftliche Bürgerrechtswechsel ins Bürgerrecht der Stadt Zürich von Ausländerinnen und Ausländern nach Einbürgerungsvrfahren |
| [https://ld.stadt-zuerich.ch/statistics/000418](https://ld.stadt-zuerich.ch/statistics/000418) | Wirtschaftliche Bürgerrechtswechsel ins schweizer Bürgerrecht nach Einbürgerungsart von Ausländerinnen und Ausländern |
| [https://ld.stadt-zuerich.ch/statistics/000417](https://ld.stadt-zuerich.ch/statistics/000417) | Wirtschaftliche Bürgerrechtswechsel ins schweizer Bürgerrecht von Ausländerinnen und Ausländern |
| [https://ld.stadt-zuerich.ch/statistics/000421](https://ld.stadt-zuerich.ch/statistics/000421) | Wirtschaftliche Bürgerrechtswechsel ins schweizer Bürgerrecht von Ausländerinnen und Ausländern nach Altersklasse und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000415](https://ld.stadt-zuerich.ch/statistics/000415) | Wirtschaftliche Bürgerrechtswechsel ins schweizer Bürgerrecht von Ausländerinnen und Ausländern nach Einbürgerungsart und bisheriger Nationalität |
| [https://ld.stadt-zuerich.ch/statistics/000423](https://ld.stadt-zuerich.ch/statistics/000423) | Wirtschaftliche Bürgerrechtswechsel ins schweizer Bürgerrecht von Ausländerinnen und Ausländern nach Kontinent; Altersklasse und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000422](https://ld.stadt-zuerich.ch/statistics/000422) | Wirtschaftliche Bürgerrechtswechsel ins schweizer Bürgerrecht von Ausländerinnen und Ausländern nach Nationalität; Altersklasse und Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000414](https://ld.stadt-zuerich.ch/statistics/000414) | Wirtschaftliche Bürgerrechtswechsel ins schweizer Bürgerrecht von Ausländerinnen und Ausländern nach bisheriger Nationalität |
| [https://ld.stadt-zuerich.ch/statistics/000244](https://ld.stadt-zuerich.ch/statistics/000244) | Wirtschaftliche Sterbefälle |
| [https://ld.stadt-zuerich.ch/statistics/000239](https://ld.stadt-zuerich.ch/statistics/000239) | Wirtschaftliche Sterbefälle nach Herkunft und Gechlecht |
| [https://ld.stadt-zuerich.ch/statistics/000229](https://ld.stadt-zuerich.ch/statistics/000229) | Wirtschaftliche Umzüge |
| [https://ld.stadt-zuerich.ch/statistics/000231](https://ld.stadt-zuerich.ch/statistics/000231) | Wirtschaftliche Umzüge nach Umzugsquartier; Umzugskreis |
| [https://ld.stadt-zuerich.ch/statistics/000230](https://ld.stadt-zuerich.ch/statistics/000230) | Wirtschaftliche Umzüge nach Umzugsquartier; Umzugskreis;10-Jahres-Altersklasse; Geschlecht und Zivilstand |
| [https://ld.stadt-zuerich.ch/statistics/000382](https://ld.stadt-zuerich.ch/statistics/000382) | Wirtschaftliche Wegzüge |
| [https://ld.stadt-zuerich.ch/statistics/000267](https://ld.stadt-zuerich.ch/statistics/000267) | Wirtschaftliche Wegzüge nach 20-Jahres-Altersklasse; Herkunft und Geschlecht; gemessen nach Ereignisdatum |
| [https://ld.stadt-zuerich.ch/statistics/000387](https://ld.stadt-zuerich.ch/statistics/000387) | Wirtschaftliche Wegzüge nach Altersklasse |
| [https://ld.stadt-zuerich.ch/statistics/000391](https://ld.stadt-zuerich.ch/statistics/000391) | Wirtschaftliche Wegzüge nach Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000392](https://ld.stadt-zuerich.ch/statistics/000392) | Wirtschaftliche Wegzüge nach Geschlecht und Zivilstand |
| [https://ld.stadt-zuerich.ch/statistics/000396](https://ld.stadt-zuerich.ch/statistics/000396) | Wirtschaftliche Wegzüge nach Konfession |
| [https://ld.stadt-zuerich.ch/statistics/000383](https://ld.stadt-zuerich.ch/statistics/000383) | Wirtschaftliche Wegzüge nach Nationalität |
| [https://ld.stadt-zuerich.ch/statistics/000407](https://ld.stadt-zuerich.ch/statistics/000407) | Wirtschaftliche Wegzüge nach Wegugskanton |
| [https://ld.stadt-zuerich.ch/statistics/000406](https://ld.stadt-zuerich.ch/statistics/000406) | Wirtschaftliche Wegzüge nach Wegzugsland |
| [https://ld.stadt-zuerich.ch/statistics/000400](https://ld.stadt-zuerich.ch/statistics/000400) | Wirtschaftliche Wegzüge von Familien |
| [https://ld.stadt-zuerich.ch/statistics/000401](https://ld.stadt-zuerich.ch/statistics/000401) | Wirtschaftliche Wegzüge von Familien nach Herkunft |
| [https://ld.stadt-zuerich.ch/statistics/000380](https://ld.stadt-zuerich.ch/statistics/000380) | Wirtschaftliche Zuzüge |
| [https://ld.stadt-zuerich.ch/statistics/000266](https://ld.stadt-zuerich.ch/statistics/000266) | Wirtschaftliche Zuzüge nach 20-Jahres-Altersklasse; Herkunft und Geschlecht; gemessen nach Ereignisdatum |
| [https://ld.stadt-zuerich.ch/statistics/000386](https://ld.stadt-zuerich.ch/statistics/000386) | Wirtschaftliche Zuzüge nach Altersklasse |
| [https://ld.stadt-zuerich.ch/statistics/000389](https://ld.stadt-zuerich.ch/statistics/000389) | Wirtschaftliche Zuzüge nach Geschlecht |
| [https://ld.stadt-zuerich.ch/statistics/000390](https://ld.stadt-zuerich.ch/statistics/000390) | Wirtschaftliche Zuzüge nach Geschlecht und Zivilstand |
| [https://ld.stadt-zuerich.ch/statistics/000395](https://ld.stadt-zuerich.ch/statistics/000395) | Wirtschaftliche Zuzüge nach Konfession |
| [https://ld.stadt-zuerich.ch/statistics/000381](https://ld.stadt-zuerich.ch/statistics/000381) | Wirtschaftliche Zuzüge nach Nationalität |
| [https://ld.stadt-zuerich.ch/statistics/000405](https://ld.stadt-zuerich.ch/statistics/000405) | Wirtschaftliche Zuzüge nach Zuzugskanton |
| [https://ld.stadt-zuerich.ch/statistics/000261](https://ld.stadt-zuerich.ch/statistics/000261) | Wirtschaftliche Zuzüge nach Zuzugsland |
| [https://ld.stadt-zuerich.ch/statistics/000404](https://ld.stadt-zuerich.ch/statistics/000404) | Wirtschaftliche Zuzüge nach Zuzugsland |
| [https://ld.stadt-zuerich.ch/statistics/000260](https://ld.stadt-zuerich.ch/statistics/000260) | Wirtschaftliche Zuzüge nach Zuzugsort |
| [https://ld.stadt-zuerich.ch/statistics/000398](https://ld.stadt-zuerich.ch/statistics/000398) | Wirtschaftliche Zuzüge von Familien |
| [https://ld.stadt-zuerich.ch/statistics/000399](https://ld.stadt-zuerich.ch/statistics/000399) | Wirtschaftliche Zuzüge von Familien nach Herkunft |
| [https://ld.stadt-zuerich.ch/statistics/000301](https://ld.stadt-zuerich.ch/statistics/000301) | Wohnfläche (m2) pro Person|
| [https://ld.stadt-zuerich.ch/statistics/000302](https://ld.stadt-zuerich.ch/statistics/000302) | Wohnfläche (m2) pro Person nach Zimmerzahl der Wohnungen|
| [https://ld.stadt-zuerich.ch/statistics/000181](https://ld.stadt-zuerich.ch/statistics/000181) | Wohnfläche in m2 (netto; exkl. leerstehende Wohnunben) pro Person |


`,

            initialEmpty: ``,
          },
          answers: {
            oneMoment: 'Einen Moment bitte...',
            serverConnectionError: 'Verbindung zum Server abgebrochen :( Bitte bald nochmals probieren.',
            otherModelButton: 'Mit mehr Kontext versuchen (Experimentell)',
            wasItUseful: 'War die Antwort hilfreich?',
            yes: 'Ja',
            no: 'Nein',
            giveFeedback: 'Feedback geben',
            readHints: 'Hinweise und Einschränkungen lesen',
            letsTryToImprove: 'Lass uns versuchen, die Antwort zu verbessern!',
            improvePrompt: 'Frage verfeinern und nochmals probieren',
            thanksForFeedback: 'Danke für Ihr Feedback!',
            unhappy: 'Unzufrieden mit der Antwort?',
          },
          hints: {
            title: 'Hinweise und Einschränkungen',
            notesTitle: 'Hinweise',
            note1:
              'Der Bot merkt sich einmal gestellte Fragen nicht. Für Verfeinerungen muss die Frage nochmals eingegeben werden.',
            note2:
              'Es ist besser ganze Fragen zu stellen, anstatt nur Stichwörter. Manchmal hilft es auch eine Frage anders zu formulieren, um eine perfektere Antwort zu erhalten.',
            note3:
              'Für mehr generelle Fragen und nicht abgedeckte Datensätze, empfehlen wir [ZüriCityGPT](https://zuericitygpt.ch/).',
            note4: 'Sollte keine Antwort kommen oder die Antwort abrupt enden, am Besten nochmals probieren.',
            note5:
              'Wir arbeiten kontinuierlich daran den Service zu verbessern. Komm also später wieder vorbei, wenn du noch nicht ganz zufrieden warst. Und darum speichern wir auch deine Fragen und Antworten bei uns, damit wir davon lernen können. Wir freuen uns auch, wenn du uns Feedback gibst, das hilft uns enorm. [Datenschutzerklärung](/privacy)',
            note6:
              'Alle Angaben ohne Gewähr. Bitte überprüfe die Information auf den offiziellen Webseiten der Stadt Zürich.',
            limitationsTitle: 'Einschränkungen',
            limitationsText: `${import.meta.env.VITE_SITE_NAME} weiss (fast) alles, 
            was am ${import.meta.env.VITE_DATE_DE} auf <1>${import.meta.env.VITE_MAIN_SITE_SHORT}</1> publiziert war.`,
            disclaimerText: 'Diese Website ist nicht mit der Stadt Zürich assoziiert.',
          },
          contact: {
            title: 'Kontakt und Feedback',
            emailLabel: 'Deine E-Mail (optional):',
            messageLabel: 'Deine Nachricht:',
            submitButton: 'Abschicken',
            feedbackReceived: 'Feedback erhalten, danke!',
            feedbackReceivedError: 'Da ist was schief gelaufen, bitte kontaktiere uns auf anderen Wegen.',
            emailText: 'Oder senden Sie eine Mail an chregu@liip.ch',
            moreContact: 'Mehr Kontaktmöglichkeiten auf',
          },
          footer: {
            credits: 'Powered by <1>Liip</1> — <3>Blog Post über das Projekt</3>',
            disclaimer: `${import.meta.env.VITE_SITE_NAME} ist nicht mit der Stadt Zürich assoziiert.`,
            selectLanguage: 'Sprache auswählen',
          },
        },
      },
      fr: {
        translation: {
          header: {
            title: `${import.meta.env.VITE_SITE_NAME}`,
            slogan: `Je suis ${
              import.meta.env.VITE_SITE_NAME
            } et je connais (presque) tout ce qui a été publié sur <1>${import.meta.env.VITE_MAIN_SITE_SHORT}</1>.`,
          },
          from: 'du',

          results: {
            initial: `${
              import.meta.env.VITE_SITE_NAME
            } peut maintenant reconnaître ta voix, même en Suisse allemand. La plupart du temps. [Clique ici pour le bouton](/?speech=1).
            
Nous continuons à travailler dur pour améliorer, agrandir et mettre à jour régulièrement ${
              import.meta.env.VITE_SITE_NAME
            }. 
                      Nous apprécions donc tous les retours. Ou même en parler autour de vous si vous êtes satisfait de ${
                        import.meta.env.VITE_SITE_NAME
                      }. `,
            initialEmpty: ``,
          },
          question: {
            label: 'Votre Question',
            placeholder: "Demandez-moi tout ce que vous voulez savoir sur l'administration de la ville de Zurich.",
            buttonText: 'Poser la question',
            microphoneLabel: 'Poser la question (avec microphone)',
            microphoneListening: 'Microphone: Maintenez le bouton enfoncé et parlez',
          },
          answers: {
            oneMoment: "Un moment s'il vous plaît...",
            serverConnectionError: 'Connexion au serveur interrompue :( Veuillez réessayer bientôt.',
            otherModelButton: 'Essayez avec plus de contexte (expérimental)',
            wasItUseful: 'La réponse vous était utile?',
            yes: 'Oui',
            no: 'Non',
            giveFeedback: 'Donner un feedback',
            readHints: 'Lire les astuces et limitations',
            letsTryToImprove: 'Essayons d’améliorer les choses!',
            improvePrompt: 'Affiner la question et réessayer',
            thanksForFeedback: 'Merci pour votre feedback!',
            unhappy: 'Insatisfait de la réponse?',
          },
          hints: {
            title: 'Astuces et Limitations',
            notesTitle: 'Astuces',
            note1: `${
              import.meta.env.VITE_SITE_NAME
            } ne se souvient pas des questions posées. Si la réponse ne vous convient pas, reprenez votre question et affinez-la pour améliorer la qualité de la réponse.`,
            note2:
              'Il est préférable de poser des questions complètes plutôt que simplement des mots-clés. Parfois, reformuler la question peut donner une meilleure réponse.',
            note3:
              "Fournir l'année en cours (par exemple, 2023) ou poser des questions plus spécifiques peut donner des réponses plus à jour.",
            note4: "Si aucune réponse n'est reçue ou si la réponse se termine brusquement, relancez votre recherche.",
            note5:
              "Nous stockons vos questions et réponses chez nous afin de pouvoir améliorer le service au fil du temps. C'est pourquoi nous apprécions si vous nous donnez des retours ; cela nous aide énormément. [Politique de Confidentialité](/privacy)",
            note6:
              'Toutes les informations sont fournies sans garantie. Veuillez vérifier les informations sur le site officiel de la ville de Zurich.',
            limitationsTitle: 'Limitations',
            limitationsText: `${import.meta.env.VITE_SITE_NAME} connaît uniquement ce qui a été publié sur <1>${
              import.meta.env.VITE_MAIN_SITE_SHORT
            }</1> avant le ${import.meta.env.VITE_DATE_FR}.`,
            disclaimerText: "Cette application n'est pas associée à la ville de Zurich.",
          },
          contact: {
            title: 'Contact et Commentaires',
            emailLabel: 'Votre E-Mail :',
            messageLabel: 'Votre message :',
            submitButton: 'Envoyer',
            feedbackReceived: 'Merci pour votre message!',
            feedbackReceivedError: 'Quelque chose s’est mal passé, veuillez nous contacter par d’autres moyens.',
            emailText: 'Ou envoyez un email à chregu@liip.ch',
            moreContact: "Plus d'options de contact sur",
          },
          footer: {
            credits: 'Propulsé par <1>Liip</1> — <3>Article sur le projet</3>',
            disclaimer: `${import.meta.env.VITE_SITE_NAME} n'est pas affilié à la ville de Zurich.`,
            selectLanguage: 'Sélectionner la Langue',
          },
        },
      },
    },
  })

export default i18n
