import { Dispatch, ReactNode, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowRight } from '../ArrowRight.tsx'
import { linkImageOnMouseClick, linkImageOnMouseOut, linkImageonMouserOver, UsedLinks } from '../helpers.ts'
import AudioPlayerWrapper from './AudioPlayerWrapper.tsx'
import MyMarkdown from './Markdown.tsx'
import marked from './marked'
import { ResultErz } from './ResultErz.tsx'

export const Result = (props: {
  queryResult: string
  query: string
  erzFunction: { query: string; function: string } | null
  linksResult: string
  changeModel: () => void
  isLoading: boolean
  maybeBadRating: boolean
  quickFeedback: ReactNode
  otherModelButton: boolean
  userId: string | null
  setLinksResult: Dispatch<SetStateAction<string>>
  usedLinksResult: UsedLinks[]
}) => {
  const { t } = useTranslation()
  const queryParams = new URLSearchParams(window.location.search)

  const DO_AUDIO_BUTTON = !!queryParams.get('audio')

  if (props.queryResult === '') {
    return null
  }
  const hasFunctionResult = props.erzFunction?.function && props.erzFunction.function.length > 0
  return (
    <>
      {hasFunctionResult && <ResultErz erzQuery={props.erzFunction} userId={props.userId} />}
      <div className="resultBox" translate="no">
        {hasFunctionResult && (
          <p>
            <em>Originale Antwort:</em>
          </p>
        )}
        <MyMarkdown
          markdown={props.queryResult + (props.isLoading ? ' ...' : '')}
          usedLinks={props.usedLinksResult}
        ></MyMarkdown>
        {DO_AUDIO_BUTTON && !props.isLoading && props.queryResult && <AudioPlayerWrapper text={props.queryResult} />}
      </div>

      {props.otherModelButton && props.maybeBadRating && (
        <>
          {' '}
          <hr />
          <div className="maybeBadRating">
            <span style={{ marginRight: '0.5em' }}>{t('answers.unhappy')}</span>
            <button type="submit" onClick={props.changeModel} className="button button--model">
              <span>{t('answers.otherModelButton')}</span>
              <ArrowRight />
            </button>
          </div>
        </>
      )}

      {props.linksResult && (
        <>
          <hr />
          {props.quickFeedback}
        </>
      )}
      {props.otherModelButton && !props.maybeBadRating && (
        <>
          <hr />

          <button type="submit" onClick={props.changeModel} className="button button--model">
            <span>{t('answers.otherModelButton')}</span>
            <ArrowRight />
          </button>
        </>
      )}
      {props.linksResult && props.linksResult !== '__NO_LINKS__' && (
        <>
          <hr />
          <div
            onMouseOver={linkImageonMouserOver}
            onMouseOut={linkImageOnMouseOut}
            onClick={linkImageOnMouseClick}
            className="resultBoxLinks"
            dangerouslySetInnerHTML={{
              // keep using marked directly instead of MyMarkdown because we need to be able to set directly HTML from the backend, which MyMarkdown doesn't allow
              __html: marked(`${props.linksResult}`, {
                headerIds: false,
                mangle: false,
              }),
            }}
          />
        </>
      )}
    </>
  )
}
